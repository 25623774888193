import React from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import TermsMenu from '../../../Navigation/FooterMenu/TermsMenu/TermsMenu'
import GamesMenu from '../../../Navigation/FooterMenu/GamesMenu/GamesMenu'
import SocialMenu from '../../../Navigation/FooterMenu/SocialMenu/SocialMenu'
import GuidesMenu from '../../../Navigation/FooterMenu/GuidesMenu/GuidesMenu'
import HowToPlayMenu from '../../../Navigation/FooterMenu/HowToPlayMenu/HowToPlayMenu'

import './Links.scss'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import { t } from 'i18next'


const Links = ({ appOptions }) => {

    return (
        <div className='link-container-main'>
            <div className='links-container'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-3 order-lg-2">
                            <HowToPlayMenu />
                            <GuidesMenu />
                        </div>
                        <div className='col-lg-3 offset-lg-1 order-lg-3'>
                            <TermsMenu />
                        </div>
                        <div className="col-lg-2 order-lg-4 ">
                            <SocialMenu social_media={appOptions && appOptions.acf && appOptions.acf.social_media} />
                            <GamesMenu />
                        </div>
                        
                        <div className="col-lg-3 order-lg-1">
                            <div className="footer-logo">
                                <Link alt="MiMa Casino - Back to homepage" title={ t("MiMa Casino - Back to homepage")} to="/">
                                    { 
                                        appOptions && appOptions.acf && appOptions.acf.logo_white && 
                                        <LazyLoadImage width="150" height="39" alt="White Logo" src={appOptions.acf.logo_white.url} />
                                    }
                                </Link>
                            </div>
                            <div className="footer-box">
                                <address>
                                    <b>MiMa Virtual Gaming N.V</b><br />
                                    { t("Abraham de Verstraat N9")}<br />
                                    { t("Curacao, Netherlands") }
                                </address>
                            </div>
                            <div className="footer-box">
                                <div className="license-box">
                                    <LazyLoad height="70px">
                                        <iframe title={ t("Gaming Curacao")} src="https://licensing.gaming-curacao.com/validator/?lh=fd1bfeebc9817dfbec07cac3b6473b25&template=tseal" width="150" height="50" style={{ border: "none" }}></iframe> 
                                    </LazyLoad>
                                </div>
                            </div>
                            <div className="footer-box">
                                <IconsBundle>
                                    <LazyLoadImage width="50" height="50" alt="18+" src="/images/footer/18plus.svg" />
                                    <LazyLoadImage width="130" height="50" alt="Sectigo SSL Wildcard" src="/images/footer/sectigo_trust_seal_lg_140x54.png" />
                                    <LazyLoadImage className="icon-link" onClick={() => { window.open('https://www.begambleaware.org/', '_blank') }} alt="BeGambleAware" src="/images/footer/begambleawareorg_white.png" />
                                </IconsBundle>
                            </div>
                                
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Links


const IconsBundle = styled.div`
    @media (min-width: 0px) and (max-width: 767px){
        text-align: center;
    }
    img{
        width: auto;
        height: 50px;
        max-height: 50px;
        max-width: 200px;
        object-fit: contain;
        @media (min-width: 0px) and (max-width: 767px){
            margin: 0px 10px;   
        }
        @media (min-width: 768px){
            margin-right: 15px;
        }
    }

    .icon-link{
        cursor: pointer;
        &:hover{
            opacity: 0.5;
        }
    }

`
