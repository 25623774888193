import React from "react";
import './Blog.scss'
import Container from "../../components/Layout/Container";
import useGetWPAxios from '../../api/useGetWPAxios';
import BlogBox from "./BlogBox";
import { t } from 'i18next';

export default function Blog(){
    const { data } = useGetWPAxios('wp/v2/posts?categories=10&_embed&per_page=100')
    return(
        <div className="blog-section">
            <div className="blog-post-section">
                <Container>
                    <h1 className="blog-title">{ t("MiMa Blog")}</h1>
                    <div className="row">
                        {
                            data && data.map((item, key) => {
                                return(
                                    <BlogBox item={item} key={key}/>
                                )
                            })
                        }
                    </div>
                </Container>
            </div>
        </div>
    );
    
}