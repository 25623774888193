export const headerMainMenu = [
    {
        label: "MiMA Poker",
        to: "/mima-games/live-poker",
        icon: "/images/icons/helper-live.svg",
    },
    {
        label: "Promotions",
        to: "/promotions",
        icon: "/images/icons/helper-promotions.svg",
    },
    {
        label: "Blog",
        to: "/blog",
    }
    /* {
        label: "MiMA Casino Poker",
        to: "/game/casino-poker_mima",
        icon: "/images/icons/helper-live.svg",
        //gameIcon: "/images/mima-games/mima_live_casino_poker-01.svg"
    }, */
]