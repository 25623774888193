import React, { useEffect, useState, useRef, useCallback } from "react"
import {Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios'

import fscreen from 'fscreen';

import { Helmet } from "react-helmet-async";

import { useUserSession } from "../contexts/UserSessionProvider"
//import { useSessionSocket } from "../contexts/EveryMatrixProvider"

//import useGetAxios from '../api/useGetAxios'
import Image from '../components/Utils/Image/Image'
import Button from './../components/Utils/Button/Button'
import ButtonLink from './../components/Utils/ButtonLink/ButtonLink'
import Container from "./../components/Layout/Container";
import styled from "styled-components";

import { useLoginPopup } from "../contexts/LoginPopupProvider";
import { useAppLoaded } from "../contexts/AppLoadedProvider";

import LoadingOutput from "../components/Output/LoadingOutput/LoadingOutput";
import { useSessionSocket } from "../contexts/EveryMatrixProvider";
import { t } from "i18next";

axios.defaults.baseURL = process.env.REACT_APP_API_URL_STAGING

export default function SingleGame({ demo = false }){

    
    const fullscreenRef = useRef();
    const { userSession } = useUserSession()
    const { appLoaded } = useAppLoaded()
    const { setLoginPopup } = useLoginPopup()

    const sessionSocket = useSessionSocket()

    const [ sessionId, setSessionId ] = useState(null)

    const params = useParams()
    const paramStake = params.stake ? params.stake : 0;
    const bigBlind = (Math.round((paramStake) * 100) / 100).toFixed(2);
    const smallBlind = (Math.round((bigBlind/2) * 100) / 100).toFixed(2);

    const minBuyFactor = 10
    const maxBuyFactor = 100

    const minBuyin = params.stake ? params.stake * minBuyFactor : bigBlind * minBuyFactor
    const maxBuyin = params.stake ? params.stake * maxBuyFactor : bigBlind * maxBuyFactor

    const [ livePoker, setLivePoker ] = useState(null)
    const [ firIframeObj, setFirIframeObj ] = useState(null)
    const [ userVerified, setUserVerified ] = useState(false)
    const [ incompleteUser, setIncompleteUser ] = useState(false)
    const [ pokerStakes, setPokerStakes ] = useState(null)

    const demoGame = params.stake && params.stake==='free'

    const navigate = useNavigate()

    const loadedGameBg = {
        backgroundImageUrl: '/images/1.jpg'
    }

    const mainHelperClass = 'col-12'

    const getLivePoker = useCallback(async () => {
        //https://mima-casino-api.stage.norway.everymatrix.com/v1/casino/games/46387095991?language=en
		let response = await axios.get(process.env.REACT_APP_API_URL_STAGING + '/v1/casino/games/'+ process.env.REACT_APP_LIVE_POKER_ID +'?language=en')
        response = await response.data[0]

        if(!response) return
        if(!response.launchUrl) return

        let setLaunchUrl = demoGame ? response.launchUrl + '&funMode=true' : response.launchUrl + '&stake=' + (params.stake ? params.stake : '1')

		setLivePoker({
            items: [
                {
                    name: 'Live Poker - Texas Hold\'em',
                    gId: 1,
                    backgroundImageUrl: '/images/1.jpg',
                    vendor: { id: 1 },
                    launchUrl: setLaunchUrl
                }
            ]
        })


    }, [demoGame, params.stake])

    useEffect(() => { getLivePoker() }, [getLivePoker])

	
	const getPokerStakes = useCallback(async () => {
        //https://mima-casino-api.stage.norway.everymatrix.com/v1/casino/games/46387095991?language=en
		let response = await axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/public/games/filtered/live/0/')
        response = await response.data
		setPokerStakes(response)
    }, [])

    useEffect(() => { getPokerStakes() }, [getPokerStakes])


    useEffect(() => {
        if(!livePoker) return
        setFirIframeObj({
            name: t(livePoker.items[0].name),
            launchUrl: livePoker.items[0].launchUrl
        })
    }, [livePoker])
    
    useEffect(() => {
        if(!sessionSocket) return
        if(!appLoaded) return
        sessionSocket.call('/user#getCmsSessionID').then(
            function (result) {
                setSessionId(result.kwargs.cmsSessionID)
            }
            , function (err) {
                console.log(err)
            }
        )
    }, [appLoaded, sessionSocket])

    useEffect(() => {
        if(!userSession) return
        setUserVerified(userSession.isEmailVerified)
        setIncompleteUser(userSession.isProfileIncomplete)
    }, [demo, userSession, userVerified, incompleteUser])

  
    const exitMegaFullscreen = useCallback(async () => {
        if (fscreen.fullscreenElement === fullscreenRef.current) {
            return fscreen.exitFullscreen();
        }
    }, []);


    if(appLoaded && livePoker && firIframeObj && sessionId){
        return (
            <>
                <Helmet>
                    <title>{ process.env.REACT_APP_MAIN_TITLE + (userVerified ? (livePoker ? ' - ' + livePoker.items[0].name + (demo ? ( t("(Demo Version)")) : '') : '') : t(" - User not verified.")) }</title>
                </Helmet>
                <SectionSingleGame className={'section-single-game' + ( userSession ? '' : ' user-not-authenticated') + (loadedGameBg && ' loaded')} style={loadedGameBg && loadedGameBg} >
                    
                    { userVerified && !incompleteUser ? (
                        <>
                            <Container className="text-center">
                                <div className="col-12">
                                    <div className="single-gamename">
                                        <Link to="/mima-games/live-poker">
                                            <div className="left-arrow"><Image src='/images/icons/icon-arrow-left.svg' /></div>
                                            <div className="game-name">
                                                <h4>{ livePoker ? livePoker.items[0].name + (demo ?  t("( Demo Version)") : '') : '...' }</h4>
                                                { paramStake!==0 && !demoGame && <div className="meta">
                                                    <div className="item">{ t("Stake: ")}{smallBlind}$ / {bigBlind}$</div>
                                                    <div className="item">{ t("Min/Max Buy-In: ")}{minBuyin}$ / {maxBuyin}$</div>
                                                    <div className="item">{ t("Max Players: 6")}</div>
                                                </div> }
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </Container>
                            <div id="game-windows-snap"></div>
                            <div ref={fullscreenRef} id="game-windows" className={"game-windows num-games-1" }>

                                { !userSession && <LoadingGame /> } 
                                {/* { error && <p>Error loading the game...</p> }  */}

                                { paramStake===0 && (
                                    <StakeSelectionPopup>
                                        <h3>{ t("Please select stake to join a table.")}</h3>
                                        <StakesRow>
                                            { pokerStakes && pokerStakes.types.map((stake, index) => {

                                                const bigBlind = (Math.round((stake) * 100) / 100).toFixed(2);
                                                const smallBlind = (Math.round((bigBlind/2) * 100) / 100).toFixed(2);

                                                return (
                                                    <StakeButton key={index} onClick={() => { navigate('/game/live-poker_mima/play/' + parseInt(stake)) }}>{ smallBlind + '$ / ' + bigBlind + '$' }</StakeButton>
                                                )
                                            }) }
                                        </StakesRow>
                                    </StakeSelectionPopup>
                                )}

                                { userSession && livePoker && !firIframeObj.launchUrl ? (
                                    <></>
                                
                                    ): <SingleGameBox 
                                            fullscreenRef={fullscreenRef}
                                            mainHelperClass={mainHelperClass} 
                                            title={firIframeObj.name} 
                                            launchUrl={firIframeObj.launchUrl} 
                                            demo={demo} 
                                            numberBox={1}
                                            sessionId={sessionId} />
                                }

                                { userSession && (
                                    <div className="fs-bar">
                                        <Button onClickEvent={exitMegaFullscreen}>{ t("Exit Fullscreen")}</Button>
                                    </div>
                                    ) 
                                }
                                
                            </div>
                        </>
                            ) : (
                            userSession && (
                                <DisabledNotice className="disabled-notice">
                                    
                                    {  incompleteUser && <p>{ t("Your profile is either blocked or incomplete. Please check the ")}<Link to="/my-account/edit-profile">{ t("Edit Profile")}</Link>{ t(" section before playing.")}</p> }
                                    
                                    { userSession.email && !userVerified && (
                                        <>
                                            <div>{ t("You need to verify your email first before playing. Please check your inbox on your email account: ")}<span>{ userSession && userSession.email }</span></div>
                                            <div className="btn-wrap d-flex">
                                                <ButtonLink className="mr-1" href="/help/contact-us" secondary>{ t("Contact support")}</ButtonLink>
                                                <ButtonLink target="_blank" rel="noreferrer" href={ "https://" + (userSession && userSession.email && userSession.email.split('@')[1]) }>{ t("Go to your inbox")}</ButtonLink>
                                            </div>
                                        </>
                                    )}
                                    
                                </DisabledNotice>
                            )
                        )}
    
                    {
                        appLoaded && !userSession && (
                        <DisabledNotice className="disabled-notice">
                            <div>{ t("You are not authorized to play this game. Please login first.")}</div>
                            <div className="btn-wrap">
                                <Button onClickEvent={() => { setLoginPopup(true) } }>{ t("Login")}</Button>
                            </div>
                        </DisabledNotice> )
                    }
    
                </SectionSingleGame>
            </>
        )
    }else{
        return <LoadingOutput>{ t("Loading game...")}</LoadingOutput>
    }

}






const SingleGameBox = ({ fullscreenRef, mainHelperClass, title, launchUrl, demo, numberBox, sessionId }) => {

    const [isActive, setActive] = useState(false);
    const [fullscreenActive, setFullscreenActive] = useState(false);

    function toggleClass(){
        setActive(!isActive)
    }

    const enterFullscreen = useCallback(async () => {
        if (fscreen.fullscreenElement) {
            await fscreen.exitFullscreen();
        }
        setFullscreenActive(true)
        return fscreen.requestFullscreen(fullscreenRef.current);
    }, [fullscreenRef]);

    const exitFullscreen = useCallback(async () => {
        if (fscreen.fullscreenElement === fullscreenRef.current) {
            return fscreen.exitFullscreen();
        }
    }, [fullscreenRef]);

    useEffect(() => {
        ['fullscreenchange','mozfullscreenchange', 'MSFullscreenChange', 'webkitfullscreenchange'].forEach( evt =>
            fscreen.addEventListener(evt, () => {
                if (!fscreen.fullscreenElement) {
                    setFullscreenActive(false)
                }
        }), false);
    }, [])


    return (
        <>
            { !demo && 
                <div className="game-menu-wrap">
                    <div className="game-menu-button" onClick={toggleClass}>
                        <Image src="/images/icons/akar-icons_more-vertical.svg" />
                    </div>
                    <div className={isActive ? 'game-menu show': 'game-menu'} id={"game-menu-" + numberBox}>
                        <ul>
                            <li>
                                <a onClick={() => { 
                                    toggleClass()
                                }} href="#game-windows-snap"><Image src="/images/icons/icon-magnet.svg" />{ t(" Snap to window")}</a>
                            </li>
                            {!fullscreenActive && (
                                <li id={"full-number-" + numberBox} onClick={() => {
                                    enterFullscreen();
                                    toggleClass()
                                    }}>
                                        <Image src="/images/icons/icon-fullscreen.svg" />{ t("FullScreen")}
                                </li>
                            )}

                            {fullscreenActive && (
                                <li id={"exit-number-" + numberBox} className="exit-number" onClick={() => {
                                    exitFullscreen();
                                    toggleClass()
                                    }}>
                                        <Image src="/images/icons/icon-fullscreen.svg" />{ t("Exit FullScreen")}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            }
            <div className="multiple-box" id={"iframe-number-" + numberBox}>
                <div className="game-iframe-wrap">
                    { launchUrl && 
                        <iframe 
                        frameBorder="0" 
                        title={title} 
                        src={ launchUrl + ( launchUrl.includes('?') ? '&' : '?') +'_sid='+ sessionId}></iframe>
                    }
                </div>
            </div>
        </>
    )
}


function LoadingGame(){
    return (
        <div className="single-game-loader">
            <div className="single-game-loader-info">
                <div>
                    <img alt="App loading..." src="/images/loader-slot.gif" />
                </div>
                <div>Loading game...</div>
            </div>
        </div>
    ) 
}


const DisabledNotice = styled.div`
    position: relative;
    width: 100%;
    min-height: 300px;
    z-index: 950;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #ff7a7a1c;
    .btn-wrap{
        margin-top: 25px;
    }
`

const SectionSingleGame = styled.section`
    position: relative;
    padding-top: 50px;
    background-size: cover;
    background-position: 50%;
    background-attachment: fixed;

    .disabled-notice{
        margin-top: -50px;
        margin-bottom: -50px;
        min-height: 60vh;
    }

    @media only screen and (max-width: 1199px) {
        padding: 25px 0px;
    }
    &:after{
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(#000000c3, #131313);
        z-index: 930;
        top: 0;
        left: 0;
        position: absolute;
    }

    & > .container{
        position: relative;
        z-index: 945;
    }

    .single-search-results {
        display: flex;
        padding: 30px 0px;
        &> div {
            min-height: 150px;
        }
    }

    .single-gamename {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;
    
        a {
            text-decoration: none;
            display: flex;
            align-items: center;
            width: fit-content;

            @media (min-width: 0px) and (max-width: 767px){
                width: 100%;
                flex: 0 0 100%;
                text-align: left;   
            }

            .left-arrow {
                display: flex;
                align-items: center;
                img {
                    margin-right: 25px;
                    width: 14px;
                    height: auto;
                }
            }
            h4 {
                text-align: left;
                font-size: 24px;
                line-height: 28px;
                color: #ffffff;
                margin: 0;
            }

            .meta{
                display: flex;
                font-size: .875rem;
                @media (min-width: 0px) and (max-width: 767px){
                    flex-direction: column;   
                }
                .item{
                    margin-right: .5rem;
                    
                    @media (min-width: 768px){
                        &:after{
                            content: '';
                            width: 8px;
                            height: 8px;
                            border-radius: 100%;
                            background: white;
                            opacity: 0.75;
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 0.5rem;
                            position: relative;
                            top: -1px;
                        }
                    }
                }

                & > .item:last-child::after{
                    display: none;
                }

            }

        }
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            .btn {
                margin-bottom: 35px;
            }
            a {
                h4 {
                    font-size: 20px;
                }
            }
        
        }
    }

    .add-game-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: #00000082;
        border-radius: 15px;
        .btn-add-game {
            max-width: 250px;
            max-height: 50px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;   
        }
        .last-game-button {
            background: none;
            position: absolute;
            bottom: -80px;
            font-size: 12px;
            top: 0;
            margin: auto;
            height: 15px;
            cursor: pointer;
            img {
                width: 13px;
                margin-left: 5px;
            }
        }
    }

    .add-game-wrapper,
    .game-iframe-wrap{
        position: relative;
        height: 0;
        iframe{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: 0;
        }
    }


    .single-game-iframe,
    .multiple-box{
        height: 100vh;
        position: relative;
        width: 100%;
        z-index: 5;
        margin-bottom: 0px;
        display: block;
        /* display: flex;
        justify-content: center;
        flex-direction: column; */
        iframe{
            background: #000000b5;
            width: 100%;
        }
    }

    #game-windows{
        height: 100vh;
        position: relative;
        z-index: 950;
    }

    .fs-bar{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        display: none;
        flex: 0 0 100%;
        z-index: 25;
        padding: 4px 8px;
        text-align: right;
        margin-top: -10px;
        align-items: center;
        justify-content: space-between;
        max-height: 40px;
        .btn{
            height: 28px;
            font-size: 14px;
        }
        .fullscreen-balance{
            img{
                display: none;
            }
        }
    }
    

    #game-windows:fullscreen{
        .fs-bar{
            display: flex;
        }
        .single-game-search{
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            max-height: 60vh;
            overflow-y: scroll;
        }
    }

    #game-windows-snap{
        position: relative;
        top: -80px;
    }

    .num-games-1{
        display: flex;
        align-items: center;
        .multiple-box,
        .game-iframe-wrap,
        .add-game-wrapper{
            max-height: 960px;
            @media (min-width: 0px) and (max-width: 1199px){
                height: 95vh;
            }
            @media (min-width: 1200px){
                height: calc(97vh - 90px);
            }
        }
    }
    
    .num-games-2{
        .multiple-box,
        .game-iframe-wrap,
        .add-game-wrapper{
            max-height: 960px;
            height: calc(100vh - 90px);
        }
    }

    .num-games-4{
        .multiple-box,
        .game-iframe-wrap,
        .add-game-wrapper{
            max-height: 960px;
            height: calc(50vh - 35px);
        }
    }

    #iframe-number-2,
    #iframe-number-4{
        iframe{
            right: 0;
            left: auto;
        }
    }

    &.user-not-authenticated::after {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000000c7;
        z-index: 900;
    }

    .search-results{
        max-width: 1750px;
        height: auto;
        max-height: 75vh;
        min-height: 685px;
    }

    .single-game-loader{
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 48%;
        background: #22202a;
        border-radius: 5px;
        border: 2px solid black;
        &-info{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img{
                width: 120px;
            }
        }
    }

    .game-menu-wrap {
        position: absolute;
        z-index: 9999;
        right: 1.25rem;
        top: -2.875rem;

        .game-menu-button{
            cursor: pointer;
            background: var(--default);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            position: absolute;
            top: 0px;
            right: 0px;
            width: 32px;
            height: 32px;
            img{
                width: 80%;
                height: auto;
            }
        }

        .game-menu {
            position: absolute;
            right: 0px;
            width: max-content;
            background: var(--default);
            padding: 15px 20px;
            border-radius: 10px;
            display: none;
            @media (min-width: 0px) and (max-width: 767px){
                top: 2.5rem;
            }
            @media (min-width: 768px){
                top: 2.5rem;
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    width: max-content;
                    margin-top: 5px;
                    a{
                        color: white;
                        text-decoration: none;
                    }
                    &:first-child{
                        margin-top: 0px;
                    }
                    img {
                        margin-right: 10px;
                        width: 14px;
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }
        .show {
            display: flex;
            justify-content: center;
        }
    }

    .single-game-vendor{
        background: #ffffff;
        border-radius: 5px;
        padding: 2px 15px;
        @media (min-width: 0px) and (max-width: 767px){
            margin-bottom: 35px;   
            height: 40px;
        }
        @media (min-width: 768px){
            padding-left: 10px;
        }
        h5{
            margin: 0;
            margin-left: 10px;
            color: #323232;
            @media (min-width: 0px) and (max-width: 767px){
                font-size: 14px;
            }
            @media (min-width: 768px){
                font-size: 16px;
            }
        }
        .single-game-vendor-icon{
            max-width: 50px;
            max-height: 50px;
            border-radius: 4px;
        }
    }


    .vendor-games-title-box {
        margin-top: 50px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
            margin: 0px;
        }
    }
    
    .simple-games-grid-slider{
        position: relative;
        z-index: 950;
    }
`



const StakeSelectionPopup = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`


const StakesRow = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`

const StakeButton = styled.span`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding: 15px;
    margin: 5px;
    border: 2px solid white;
    border-radius: 35px;
    font-size: 18px;
    font-weight: bold;
    flex: 1 0 auto;
    background: linear-gradient(to bottom, var(--orange) , var(--orange-hover));
    &:hover{
        background: #6e390b;
    }
`