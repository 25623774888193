import React, { useEffect, useState, useCallback} from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import axios from 'axios'
import styled, { css } from 'styled-components'

import { liveTables } from '../../../data/tablesData'
import { useAppOptions } from '../../../contexts/AppOptionsProvider'
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider'
import { useUserSession } from '../../../contexts/UserSessionProvider'

const utcHours = new Date().getUTCHours()
const privatePlayers1 = Math.floor(utcHours / 10) + 4
const privatePlayers2 = Math.floor(utcHours / 10) + 3
const privatePlayers3 = Math.floor(utcHours / 10) + 2

const videoTables = [17]
const highlightTables = [7]

const privateRealTables = [
    {
        tableName: 'Royal Flush',
        private: true,
        table_id: 1011,
        table_auto_mode: false,
        table_small_blind: 5,
        table_big_blind: 10,
        table_demo_mode: false,
        table_live_players: privatePlayers1
    },
    {
        tableName: 'Feels Like Vegas',
        private: true,
        table_id: 1022,
        table_auto_mode: false,
        table_small_blind: 10,
        table_big_blind: 20,
        table_demo_mode: false,
        table_live_players: privatePlayers2
    },
    {
        tableName: 'Free Rollers',
        private: true,
        table_id: 1033,
        table_auto_mode: false,
        table_small_blind: 100,
        table_big_blind: 200,
        table_demo_mode: true,
        table_live_players: privatePlayers3
    },
]

export default function LivePokerLobbyTable({ currentTable=null, hide=false, innerRef, setLobbyRefWrap }){

    const appOptions = useAppOptions()
    const { userSession } = useUserSession()
    const [tableType, setTableType] = useState('all')
    const [stakeOrder, setStakeOrder] = useState('asc')
    const [filteredAllGames, setFilteredAllGames] = useState()
    const [allGames, setAllGames] = useState([])
    const bannedPlayers = appOptions && appOptions.acf && appOptions.acf.banned_players ? appOptions.acf.banned_players : []
    
    /* eslint-disable */
	const getPokerGames = useCallback(async () => {
		
        let response = await axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/games/public/all/')
        response = await response.data
        
        let fetchAllGames = []
        fetchAllGames = [...response, ...privateRealTables]
        
        for(let i = 0; i<fetchAllGames.length; i++){
            //if(fetchAllGames[i] && fetchAllGames[i].table_auto_mode) continue
            fetchAllGames[i].table_small_blind = parseFloat(fetchAllGames[i].table_small_blind)
            fetchAllGames[i].table_big_blind = parseFloat(fetchAllGames[i].table_big_blind)
        }

        setAllGames(sortByStake(fetchAllGames, stakeOrder))

    }, [])

    const sortByStake = (games = [], order = 'asc') => {
        const sortedData = games.sort(function(a, b){
            if(order==='desc'){
                return a.table_demo_mode - b.table_demo_mode || b.table_big_blind - a.table_big_blind;
            }else{
                return a.table_demo_mode - b.table_demo_mode || a.table_big_blind - b.table_big_blind;
            }
        })
        return sortedData
    }


    useEffect(() => {

        let games = []

        if(tableType==='live'){
            const filteredGames = allGames.filter(function(item) { return item.table_demo_mode === false })
            games = filteredGames
        }

        if(tableType==='online'){
            const filteredGames = allGames.filter(function(item) { return item.table_demo_mode !== false })
            games = filteredGames
        }

        if(tableType==='all'){
            games = allGames
        }

        setFilteredAllGames(sortByStake(games, stakeOrder))

    }, [tableType, allGames])
    /* eslint-disable */

    useEffect(() => { getPokerGames() }, [getPokerGames])
    useEffect(() => { if(innerRef && innerRef.current){ setLobbyRefWrap(innerRef.current) } }, [innerRef, setLobbyRefWrap])
    useEffect(() => { 
        const interval = setInterval(() => { getPokerGames() }, 30000);
        return () => clearInterval(interval);
    }, [getPokerGames]);

    if(hide){ return <></> }

    return (
        <LobbySection ref={innerRef} id="poker-lobby">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-8">

                        <TableTypeSection>
                            <Heading>
                                <div className='iconTitle'>
                                <Icon width="93" height="92" alt="Live Dealer Tables Icon" src="/images/icons/icon-real-money.svg" />
                                <Title>
                                    {   appOptions && 
                                        appOptions.acf.poker_tables_lobby && 
                                        appOptions.acf.poker_tables_lobby.live_tables && 
                                        <h3 dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.title }} /> }
                                </Title>
                                </div>
                                <div className='iconDesc'> 
                                {   appOptions && 
                                        appOptions.acf.poker_tables_lobby && 
                                        appOptions.acf.poker_tables_lobby.live_tables && 
                                        <p dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.description }} /> }
                                </div>
                            </Heading>
                            <div className="row">
                                <div className="col-12">
                                    <TableFilters>
                                        <FilterOption>
                                            <input 
                                                onChange={() => { setTableType('all') }} 
                                                type="radio" 
                                                name="filterByType" 
                                                checked={tableType==='all'} 
                                                value="all" /> 
                                                <TableDesktopLabel>{ t("All Tables") }</TableDesktopLabel>
                                                <TableMobileLabel>{ t("All") }</TableMobileLabel>
                                        </FilterOption>
                                        <FilterOption>
                                            <input 
                                                onChange={() => { setTableType('live') }} 
                                                type="radio" 
                                                name="filterByType" 
                                                checked={tableType==='live'} 
                                                value="live" /> 
                                                <TableDesktopLabel>{ t("Real Money") }</TableDesktopLabel>
                                                <TableMobileLabel>{ t("Real $") }</TableMobileLabel>
                                        </FilterOption>
                                        <FilterOption>
                                            <input 
                                                onChange={() => { setTableType('online') }} 
                                                type="radio" 
                                                name="filterByType" 
                                                checked={tableType==='online'} 
                                                value="online" /> 
                                                <TableDesktopLabel>{ t("Free Tokens") }</TableDesktopLabel>
                                                <TableMobileLabel>{ t("Free $") }</TableMobileLabel>
                                        </FilterOption>
                                    </TableFilters>
                                    <TableHeadingsBox>
                                        <HeadingColumn className="name">{ t("Name")}</HeadingColumn>
                                        <HeadingColumn className="type">{ t("Type")}</HeadingColumn>
                                        <HeadingColumn sort={stakeOrder} onClick={() => {
                                            const order= stakeOrder==='asc' ? 'desc' : 'asc'
                                            setStakeOrder(order)
                                            setFilteredAllGames(sortByStake(filteredAllGames, order))
                                        }} className="stake sortable">{ t("Stake")}</HeadingColumn>
                                        <HeadingColumn className="players-online">{ t("Players")}</HeadingColumn>
                                        <HeadingColumn className="join">{ t("Play now")}</HeadingColumn>
                                    </TableHeadingsBox>
                                </div> 
                                { 
                                    <>
                                        { filteredAllGames && filteredAllGames.map((item, index) => {
                                            
                                            if(userSession){
                                                const isBanned = bannedPlayers.some(player => parseInt(player.table_id) === item.table_id && player.username === userSession.username);
                                                if (isBanned) return <React.Fragment key={index}></React.Fragment>
                                            }

                                            if(item && item.table_id === 2) return <React.Fragment key={index}></React.Fragment>
                                            //if(item && item.table_auto_mode) return <React.Fragment key={index}></React.Fragment>
                                                return (
                                                    <div key={index} className="col-lg-12">
                                                        <TableBox 
                                                            name={liveTables[item.table_id] ? liveTables[item.table_id] : item.tableName} 
                                                            type={item.table_demo_mode ? "online" : "live"}
                                                            tableType={item.table_auto_mode ? 'auto' : 'dealer'} 
                                                            free={item.table_demo_mode} 
                                                            iscurrent={currentTable===item.table_id ? 1 : 0} 
                                                            table={item}
                                                            privateTable={item.private} />
                                                    </div>
                                                )
                                            }) 
                                        }
                                    </>
                                }
                            </div>
                        </TableTypeSection>

                    </div>
                </div>
            </div>
        </LobbySection>
    )
}

const TableBox = ({ name = "Poker Table", type='live', tableType='dealer', table, iscurrent = 0, free = true, privateTable = false }) => {

    const { lang } = useUserLang()
    let toLink = privateTable ? '' : (iscurrent===0 ? "/game/live-poker_mima/play"+ (free ? "/free/" : "/") + table.table_game_id : "")
    toLink = lang==='en' ? toLink : "/" + lang + toLink

    const isWebcamFeature = videoTables.includes(table.table_id)
    const isLiveDealerTable = tableType==='dealer' && !videoTables.includes(table.table_id)
    const isAutoTable = tableType==='auto' && !videoTables.includes(table.table_id)

    return (
        <PokerTableBox
            as={!privateTable ? Link : 'div'}
            highlighted={videoTables.includes(table.table_id) || highlightTables.includes(table.table_id) ? '1' : '0'}
            iscurrent={iscurrent}
            type={type}
            id={table.table_id}
            to={ toLink }>

            <h3 className="name">
                { isLiveDealerTable && <TableTypeIcon src="/images/icons/icon-table-dealer.svg" /> }
                { isAutoTable && <TableTypeIcon src="/images/icons/icon-table-virtual.svg" /> }
                { isWebcamFeature && <TableTypeIcon src="/images/icons/icon-webcam.svg" /> }
                <div className="name-wrap">
                    { name }
                    { isLiveDealerTable && <div>live dealer</div> }
                    { isAutoTable && <div>virtual table</div> }
                    { isWebcamFeature && <div>live dealer/ webcam</div> }
                </div>
            </h3>

            <div className="type">{ type==='live' ? 'Real Money' : 'Free Tokens' }</div>
            <div className="type-mobile">
                <div>Type: <b>{ type==='live' ? 'Real Money' : 'Free Tokens' }</b></div>
                <div>Stake: 
                { table.table_small_blind && table.table_big_blind && (
                    <b> { table.table_small_blind } $ / { table.table_big_blind } $</b>
                )}</div>
                <div>Players: <b>{ table.table_live_players } / 6</b></div>
            </div>

            <div className="stake">
                { table.table_small_blind && table.table_big_blind && (
                    <div className="item">{ table.table_small_blind } $ / { table.table_big_blind } $</div>
                )}
            </div>

            
            <CurrentPlayers className="players-online">
                <PlayerNum>{ table.table_live_players } / 6</PlayerNum>
            </CurrentPlayers>

            <div className="join">
                { iscurrent===1 && <CurrentPlaying>{ t('Currently playing') }</CurrentPlaying> }
                { iscurrent!==1 && !privateTable && <JoinButton>{ t('Join') }</JoinButton> }
                { iscurrent!==1 && privateTable && <PrivateOnly>{ t('Private') }</PrivateOnly> }
            </div>

        </PokerTableBox>
    )
}




const LobbySection = styled.section`
    padding: 2rem 0;
    padding-bottom: 7rem;
    border-top: 5px solid var(--orange);
    margin-bottom: 2rem;
    @media (min-width: 0px) and (max-width: 767px){
        padding: 0rem 0;
    }
`

const CurrentPlaying = styled.span``

const CurrentPlayers = styled.div``

const PlayerNum = styled.div`
    font-size: 1.25rem;
    font-weight: 800;
    @media (min-width: 0px) and (max-width: 767px){
        font-size: 0.825rem;
    }
`


const TableTypeSection = styled.div`
    h3{
        @media (min-width: 0px) and (max-width: 767px){
            font-size: 1.875rem;
        }
        @media (min-width: 768px){
            font-size: 2.5rem;
        }
        margin: 0;
    }
`


const liveCss = css`
    background: linear-gradient(135deg, var(--casino) 0%, var(--orange) 100%);
    &:hover{
        background: var(--orange);
    }
`

const onlineCss = css`
    background: linear-gradient(135deg, var(--default) 0%, var(--casino) 100%);
    &:hover{
        background: var(--default);
    }
`

const JoinButton = styled.div`
    padding: 0.35rem 1rem;
    border-radius: 0.5rem;
    font-size: 0.825rem;
    border: 2px solid white;
    display: inline-block;
    font-weight: bold;
    transition: all .2s ease-out;
    width: 100%;
    text-align: center;
    @media (min-width: 0px) and (max-width: 767px){
        font-size: 0.5rem;
    }
`

const HeadingColumns = css`
    .name{ 
        width: 28%;
        @media (min-width: 0px) and (max-width: 767px){
            width: 45%;
        }
    }
    .type{ 
        width: 20%;
        @media (min-width: 0px) and (max-width: 767px){
            display: none;
        }
    }
    .type-mobile{
        @media (min-width: 0px) and (max-width: 767px){
            width: 43%;
        }
        @media (min-width: 768px){
            display: none;
        }
    }
    .stake{ 
        width: 20%; 
        @media (min-width: 0px) and (max-width: 767px){
            display: none;
        }
    }
    .players-online{ 
        width: 20%;
        @media (min-width: 0px) and (max-width: 767px){
            display: none;
        }
    }
    .join{ 
        width: 12%;
        text-align: center;
        @media (min-width: 0px) and (max-width: 767px){
            width: 17%;
        }
    }
`

const HeadingColumn = styled.div`
    &.name{ width: 28%; }
    &.type{ width: 20%; }
    &.stake{ width: 20%; }
    &.players-online{ width: 20%; }
    &.join{ 
        width: 12%;
        text-align: center;
    }
    @media (min-width: 0px) and (max-width: 767px){
        font-size: 0.75rem;
    }
    &.sortable{
        cursor: pointer;
        display: flex;
        align-items: center;
        &:hover{
            opacity: 0.5;
        }
        &:after{
            content: '';
            display: block;
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #FFF;
            flex: 0 0 6px;
            margin-left: 1rem;
            transition: all .2s ease-out;
            transform: ${props => props.sort==='asc' ? css`rotateZ(180deg)` : css`rotateZ(0deg)` };
        }
    }

`

const PokerTableBox = styled(Link)`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin: 0rem 0;
    border-radius: 0;
    text-decoration: none;
    border: 1px solid white;
    border-top: 0;
    padding: 0.5rem 0.825rem;
    min-height: 48px;
    font-size: 1rem;
    
    ${props => props.highlighted==='1' ? `
        transform: scale(1.025);
        border-radius: 1rem;
        z-index: 15;
        box-shadow: 0px 0px 15px var(--orange);
        border: 2px solid white;
        @media (min-width: 0px) and (max-width: 767px){
            transform: scale(1.05);
        }
    ` : ``}

    @media (min-width: 0px) and (max-width: 767px){
        font-size: 0.75rem;
        border: 1px solid white;
    }
    ${props => props.type==='live' ? liveCss : onlineCss}
    ${props => props.iscurrent===1 ? css`
    pointer-events: none;
    opacity: 0.5;
    ` : ``}
    h3{
        font-size: 0.95rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        @media (min-width: 0px) and (max-width: 767px){
            font-size: 0.75rem;
        }
        div{
            div{
                font-size: 0.75rem;
                font-weight: bold;
            }
        }
    }

    h3, h4, p{
        margin: 0;
    }

    &:hover{
        ${JoinButton}{
            background: white;
            color: black;
        }
    }

    ${HeadingColumns}

`


const TableHeadingsBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1.5rem;
    background: linear-gradient(135deg, #333 0%, #000 100%);
    border-radius: 0rem;
    border: 1px solid #ffffff;
    font-weight: bold;
    & > div{
        position: relative;
    }
    @media (min-width: 0px) and (max-width: 767px){
        display: none;
    }
`

const Heading = styled.div`
    margin-top: 4rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 0px) and (max-width: 767px){
        margin-top: 2rem;
        flex-direction: row;
        align-items: flex-start;
    }
    @media (min-width: 768px){
        display: flex;
    }
    h3{
        font-size: 2rem;
        max-width: 200px;
    }
    p{
        margin: 0;
        @media (min-width: 768px) and (max-width: 1630px){
            min-height: 80px;
        }
    }
    .iconTitle{
        justify-content: center;
        align-items: center;
        display: flex;
        @media (min-width: 0px) and (max-width: 767px){
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
    .iconDesc {
        margin-top: 15px;
        margin-bottom: 0px;
        @media (min-width: 0px) and (max-width: 767px){
            margin-top: 10px;
            margin-bottom: 35px;
        }
    }
`

const Icon = styled.img`
    width: auto;
    @media (min-width: 0px) and (max-width: 767px){
        height: 70px;
        margin-bottom: 0.5rem;
    }
    @media (min-width: 768px){
        height: 90px;
    }
    margin-right: 1rem;
    padding: 0.85rem;
    border-radius: 1rem;
    border: 2px solid var(--orange);
`


const Title = styled.div``

const PrivateOnly = styled.div`
    padding: 0.5rem 0;
`

const TableFilters = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
        @media (min-width: 0px) and (max-width: 767px){
            justify-content: space-between;
            margin-bottom: 0.5rem;
        }
`

const FilterOption = styled.label`
    margin-left: 1rem;
    border-radius: 0.5rem;
    border: 1px solid white;
    padding: 0.5rem 1rem;
    padding-left: 0.75rem;
    cursor: pointer;
    transition: all .2s ease-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 1rem;


    &:hover{
        background: gray;
    }
        @media (min-width: 0px) and (max-width: 767px){
        font-size: 14px;
        flex: 0 0 calc(33.3334% - 6px);
        margin: 0;        
        }
`

const TableDesktopLabel = styled.span`
    @media (min-width: 0px) and (max-width: 767px){
        display: none;
    }
    @media (min-width: 768px){
        display: block;
    }
`
const TableMobileLabel = styled.span`
    @media (min-width: 0px) and (max-width: 767px){
        display: block;
    }
    @media (min-width: 768px){
        display: none;
    }

`

const TableTypeIcon = styled.img`
    width: auto;
    height: 2rem;
    margin-right: 0.5rem;
    @media (min-width: 768px){
        margin-right: 1rem;
        margin-left: 0.5rem;
    }
`