import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { Helmet } from 'react-helmet-async'

import Container from "../../components/Layout/Container"
import useGetWPAxios from '../../api/useGetWPAxios'
import { useParams } from "react-router-dom"

import ErrorOutput from "../Output/ErrorOutput/ErrorOutput"
import LoadingOutput from "../Output/LoadingOutput/LoadingOutput"

import './BlogPost.scss';
import BlogRelated from "./BlogRelated";
import { t } from 'i18next';
import ShareButtons from "../Utils/SocialShareButtons"

export default function BlogPost(){
    const params = useParams()
    const location = useLocation();
    const currentPathname = location.pathname;
    const fullUrl = `https://mima.casino${currentPathname}`;
    
    const { data, error } = useGetWPAxios('wp/v2/posts?slug='+params.slug+'&_embed')
    const [currentPostSlug, setCurrentPostSlug] = useState();
    const [tagsArray, setTagsArray] = useState([]);

    useEffect(() => {
        if(!data) return
        setCurrentPostSlug(data[0].slug);
        const TagsArray = data[0]._embedded['wp:term'][1].map(tag => tag.name);
        setTagsArray(TagsArray);
    }, [data])

    if(error) return <ErrorOutput>{ t("Error loading data...")}</ErrorOutput>
    if(!data && !error) return <LoadingOutput>{ t("Loading data...")}</LoadingOutput>

    return(
        <>
            <Helmet>
                <title>{ t("MiMa Casino Blog - ")}{ data[0].title.rendered }</title>
                { data[0]._embedded && data[0]._embedded['wp:featuredmedia'] && (
                    <>
                        { data[0]._embedded['wp:featuredmedia'] && <meta property="og:image" content={ data[0]._embedded['wp:featuredmedia']['0'].source_url } /> }
                        <meta property="og:image:alt" content={ data[0].title.rendered } />
                    </>
                )}
            </Helmet>
            <div className="single-blog-page">
                <Container>
                    <div className="col-12 single-post-container">
                        <div className="single-post">
                            <p className="blog-date">
                                {data && data[0].date.split("T")[0]}
                            </p>
                            <h1>{ data && data[0].title.rendered }</h1>
                            {data && data[0]._embedded['wp:featuredmedia'] && <div className="featured-media"><img alt={data[0].title.rendered} src={data[0]._embedded['wp:featuredmedia']['0'].source_url} /></div>}
                        
                            {data && <div className="blog-content-box" dangerouslySetInnerHTML={{__html: data[0].content.rendered}} /> }
                        </div>
                    </div>
                    <ShareButonsWrap>
                        <ShareTitle>{ t("Share us on social media")}</ShareTitle>
                        <ShareButtons title={data[0].title.rendered} url={fullUrl} twitterHandle="mima_casino" tags={tagsArray} media={data[0]._embedded['wp:featuredmedia'] && data[0]._embedded['wp:featuredmedia']['0'].source_url}/>
                    </ShareButonsWrap>
                    <BlogRelated slug={currentPostSlug} />
                </Container>
            </div>
        </>
    );
}

const ShareButonsWrap = styled.div `
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    margin: 30px;
    margin-top: 0;
        
    @media(min-width: 992px) {
        margin: 60px;
        margin-top: 0;
    }

    @media(min-width: 1070px) {
        margin: auto;
        margin-top: 0;
    }
    
    >div {

        button {
            margin-right: 10px;
            margin-bottom: 5px;
        }
    }
`

const ShareTitle = styled.h3 `

`