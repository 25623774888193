import React, { lazy, Suspense, useEffect } from 'react'
import CacheBuster from 'react-cache-buster'
import ReactPixel from 'react-facebook-pixel'

import './i18n'

import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import AppProvider from './contexts/AppProvider'
import ScrollToTop from './components/Utils/ScrollToTop'
import AppLoader from './components/Utils/AppLoader/AppLoader'
import ProtectedRoute from './components/Authentication/ProtectedRoute/ProtectedRoute'

import MLHB from './components/Layout/MainLayoutHeroBanner'
import MainLayout from './components/Layout/MainLayout'
import ThankYou from './components/Utils/ThankYou/ThankYou'

import Footer from './components/General/Footer/Footer'


import SingleGameLivePoker from './pages/SingleGameLivePoker'
import SingleGameLivePokerFree from './pages/SingleGameLivePokerFree'

//Account Pages
import Login from './pages/Auth/Login'
import Register from './pages/Auth/Register'

//General Page Templates
import ChangePhoneNumberPage from './pages/Profile/ChangePhoneNumberPage'
import LegalPage from './pages/LegalPage'
import GuidePage from './pages/GuidePage'
import HowToPlayPage from './pages/HowToPlayPage'
import AboutPage from './pages/AboutPage'
import BlogPage from './pages/BlogPage'
import BlogPostPage from './pages/BlogPostPage'
import PayPalLive from './pages/PayPalLive'
//import PayPalTest from './pages/PayPalTest'

import Landing from './pages/Landing'
import OurTeamPage from './pages/OurTeamPage'
import { useTranslation } from 'react-i18next'
import { useUserLang } from './contexts/LanguageSwitcherProvider'

const Header = lazy(() => import('./components/General/Header/Header'))

const LandingPromoPage = lazy(() => import('./pages/LandingPromoPage'))
const LandingGamePage = lazy(() => import('./pages/LandingGamePage'))
const LandingPromoPageFreePlay = lazy(() => import('./pages/LandingPromoPageFreePlay'))
const PromotionsPage = lazy(() => import('./pages/PromotionsPage'))

const PersonalInformationPage = lazy(() => import("./pages/Profile/PersonalInformationPage"))
const ManageBalancePage = lazy(() => import('./pages/Profile/ManageBalancePage'))
const DepositPage = lazy(() => import('./pages/Profile/DepositPage'))
const WithdrawPage = lazy(() => import('./pages/Profile/WithdrawPage'))
const BonusesPage = lazy(() => import('./pages/Profile/BonusesPage'))
const TransactionHistoryPage = lazy(() => import('./pages/Profile/TransactionHistoryPage'))
const DepositLimit = lazy(() => import('./pages/Profile/DepositLimitPage'))
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'))
const FailedPage = lazy(() => import('./pages/FailedPage'))
const DepositLimitsChangePage = lazy(() => import('./pages/Profile/DepositLimitsChangePage'))
const Activate = lazy(() => import('./pages/Auth/Activate' ))
const VerifyEmail = lazy(() => import('./pages/Auth/VerifyEmail' ))
const ChangePasswordPage = lazy(() => import('./pages/Auth/ChangePasswordPage' ))
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'))
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'))
const SelfExclusionPage = lazy(() => import('./pages/Profile/SelfExclusionPage'))
const SinglePromotion = lazy(() => import('./components/Utils/SinglePromotion/SinglePromotion'))
//const TestFunctionPage = lazy(() => import('./pages/TestFunctionPage'))

const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: false, // enable logs
};

function App() {

	const isProduction = process.env.NODE_ENV === 'production'
	const params = useParams()
	const { i18n } = useTranslation()
	const { lang } = useUserLang()

	useEffect(() => {
		ReactPixel.init('1001093137773789', {}, options);
	}, [])

	useEffect(() => {
		ReactPixel.pageView();
	}, [params])

	useEffect(() => {
		if(localStorage.getItem("lang")){
			i18n.changeLanguage(localStorage.getItem("lang"))
		}
	}, [i18n])

	return (
		<CacheBuster
		currentVersion='1.2001'
		isEnabled={isProduction} //If false, the library is disabled.
		isVerboseMode={false} //If true, the library writes verbose logs to console.
		>
			<Router>
				<ScrollToTop>
					<HelmetProvider>
						<AppProvider>

							<Suspense fallback={<></>}>
								<Header />
							</Suspense>

								<MainLayout>
									<Suspense fallback={<></>}>
										<Routes>
											{ lang === 'en' && <Route path={`*`} element={<LocaleRouterWrapper />} /> }
											{ lang !== 'en' && <Route path={`/:locale/*`} element={<LocaleRouterWrapper />} /> }
										</Routes>
									</Suspense>
								</MainLayout>

							<Footer />
							<AppLoader />

						</AppProvider>
					</HelmetProvider>
				</ScrollToTop>
			</Router>
		</CacheBuster>
	)
}

export default App;


const LocaleRouterWrapper = () => {

	const { locale } = useParams()
	const { setLang } = useUserLang()
	const { t } = useTranslation()

	useEffect(() => {
		if(locale){
			setLang(locale)
		}else{
			setLang('en')
		}
	}, [locale, setLang])

	return (
		<Routes>
			<Route index element={<MLHB><Landing/></MLHB>} />
			<Route path="*" element={<ThankYou title='404' text={t('Page not found...')} showContact={false} />} />

			<Route exact path="/login" element={<Login/>} />
			<Route exact path="/register" element={<Register/>} />
			
			<Route exact path="/mima-games/:slug" element={<LandingPromoPage slugExtension='mima' />} />
			<Route exact path="/mima-games/coming-soon/:slug" element={<LandingGamePage slugExtension='mima' />} />
			<Route exact path="/mima-games/free-tables" element={<LandingPromoPageFreePlay />} />

			<Route exact path="/promotions" element={<PromotionsPage/>} />
			<Route path="/promotions/:slug" element={<SinglePromotion/>} />

			<Route exact path="/activate/:uuid64/:token" element={<Activate/>} />
			<Route exact path="/verify-email" element={<VerifyEmail/>} />
			<Route exact path="/forgot-password" element={<ForgotPassword/>} />
			<Route exact path="/password_reset/confirm/:token" element={<ResetPassword/>} />
			
			<Route element={<ProtectedRoute />}>

				{/* <Route exact path="/paypal-deposit-test/" element={<PayPalTest/>} /> */}
				<Route exact path="/paypal-deposit/" element={<PayPalLive/>} />

				<Route exact path="/game/texasholdempoker_mima" element={<SingleGameLivePoker />} />
				<Route exact path="/game/live-poker_mima/play/:external_id" element={<SingleGameLivePokerFree />} />
				<Route exact path="/game/live-poker_mima/play/free/:external_id" element={<SingleGameLivePokerFree />} />

				<Route exact path="/my-account/edit-profile" element={<PersonalInformationPage/>} />
				{/* <Route exact path="/my-account/change-email" element={<ChangeEmailPage/>} /> */}
				<Route exact path="/my-account/change-password" element={<ChangePasswordPage/>} />
				<Route exact path="/my-account/change-phone-number" element={<ChangePhoneNumberPage/>} />
				
				<Route exact path="/my-account/manage-balance" element={<ManageBalancePage/>} />
				<Route exact path="/my-account/deposit" element={<DepositPage/>} />
				<Route exact path="/my-account/deposit/:amount" element={<DepositPage/>} />
				<Route exact path="/my-account/withdrawal" element={<WithdrawPage/>} />

				<Route exact path="/my-account/bonuses" element={<BonusesPage/>} />
				<Route exact path="/my-account/transaction-history" element={<TransactionHistoryPage/>} />
				<Route exact path="/my-account/deposit-limit" element={<DepositLimit/>} />
				<Route exact path="/my-account/deposit-limit/:type" element={<DepositLimitsChangePage/>} />
				<Route exact path="/my-account/deposit/thank-you-page" element={<ThankYouPage/>} />
				<Route exact path="/my-account/deposit/failed-page" element={<FailedPage/>} />
				<Route exact path="/my-account/self-exclusion" element={<SelfExclusionPage/>} />

			</Route>

			<Route exact 
				path="/register-thank-you" 
				element={<ThankYou src='/images/thankyoupositive.png' 
				title='Thank You for Registering!' 
				text='You will receive a verification email to activate your account. Please check your inbox.'
				button={{ to: '/login', label: 'Continue to Login'  }} />} />
			
			<Route exact path="/blog" element={<BlogPage/>} />
			<Route exact path="/our-team" element={<OurTeamPage/>} />
			<Route exact path="/blog/:slug" element={<BlogPostPage/>} /> 
			<Route exact path="/how-to-play/:slug" element={<HowToPlayPage />} />
			
			<Route exact path="/contact-us" element={<AboutPage slug="contact-us" />} />
			<Route exact path="/security-privacy/:slug" element={<LegalPage/>} />
			<Route exact path="/guides/:slug" element={<GuidePage/>} />
			{/* <Route path="/test-function-page" element={<TestFunctionPage/>} /> */}
		</Routes>
	)
}