import React from 'react'
import './Blog.scss'
import { Link } from 'react-router-dom'
import { t } from 'i18next';
import { useUserLang } from '../../contexts/LanguageSwitcherProvider';

const BlogBox = ({item}) => {

    const { lang } = useUserLang()

    return (
        <div className="blog-container-box col-lg-4 col-xl-3 col-sm-6">
            <div className="post-box">
                <div className="post-body">
                    <Link to={(lang !== 'en' ? '/' + lang : '') + '/blog/'+item.slug} className="link">
                        <div className="post-image-box">
                            <div className="post-image"
                                style={{ 
                                    backgroundImage: 'url("'+item._embedded['wp:featuredmedia']['0'].media_details.sizes['medium_large'].source_url+'")' 
                                }}
                            ></div>
                        </div>
                    </Link>
                    <div className="blog-intro">
                        <div className="blog-date">
                            {item && item.date.split('T')[0]}
                        </div>
                        <div className='blog-content-container'>
                            <Link to={(lang !== 'en' ? '/' + lang : '') + '/blog/'+item.slug} className="title-link">
                                <h2>{item.title.rendered}</h2>
                            </Link>
                            <div className="post-content"
                                dangerouslySetInnerHTML={{__html: item.excerpt.rendered.split(" ").splice(0,20).join(" ")+' ...'}}
                            />
                        </div>
                        <Link to={(lang !== 'en' ? '/' + lang : '') + '/blog/'+item.slug} className="link btn">{ t("READ MORE")}</Link>
                    </div>
                </div>                 
            </div>
        </div>
    )
}

export default BlogBox