import React from 'react'
import useGetWPAxios from '../../api/useGetWPAxios';
import RelatedBlogBox from "../Blog/RelatedBlogBox";
import '../Blog/Blog.scss'
import Container from '../Layout/Container';
import { t } from 'i18next';
import Spacer from '../Utils/Spacer/Spacer';

const BlogRelated = ({slug}) => {
    const { data } = useGetWPAxios('wp/v2/get_related_posts?slug=' + slug)
    return (
        <div className="related-posts">
            <Container>
                <div className="col-12">
                    <h3>{ t("Related Articles")}</h3>
                    <div className="row">
                        { data && data.map((item,key) => {
                            return(
                                <RelatedBlogBox title={item.title} content={item.content} image={item.image} date={item.date} slug={item.slug} key={key}/>
                            )
                        }) }
                    </div>
                </div>
                <Spacer />
                <Spacer />
                <Spacer />
            </Container>
        </div>
    )
}

export default BlogRelated