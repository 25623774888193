import React from 'react'
import LazyLoad from 'react-lazyload'
import { useAppMainBanner } from '../contexts/AppOptionsProvider'
import { useUserSession } from '../contexts/UserSessionProvider'

import Seo from '../components/General/Seo/Seo'

//import MimaGamesSection from '../components/Sections/MimaGamesSection/MimaGamesSection'
//import LeaderboardHands from '../components/Sections/LeaderboardHandsSection/LeaderboardHandsSection'
import Spacer from '../components/Utils/Spacer/Spacer'
import FaqSection from '../components/Sections/FaqSection/FaqSection'

import SubscribeSection from '../components/Sections/SubscribeSection/SubscribeSection'
import LivePokerLobbyTable from '../components/Sections/LivePokerLobby/LivePokerLobbyTable'
import LivePokerLobbyTableBlurred from '../components/Sections/LivePokerLobby/LivePokerLobbyTableBlurred'
import InfoIconSection from '../components/Sections/InfoIconSection/InfoIconSection'
import DetailedFancyInfoSection from '../components/Sections/DetailedFancyInfoSection/DetailedFancyInfoSection'
import WelcomeSection from '../components/Sections/WelcomeSection/WelcomeSection'
//import LeaderboardHands from '../components/Sections/LeaderboardHandsSection/LeaderboardHandsSection'
//import LeaderboardPointSection from '../components/Sections/LeaderboardPointsSection/LeaderboardPointSection'
//import { useAppOptions } from '../contexts/AppOptionsProvider'

function Landing() {

    const appBanner = useAppMainBanner()
	const { userSession } = useUserSession()
	//const appOptions = useAppOptions()

	return (
		<>
			<Seo />
			
			{ userSession && <LivePokerLobbyTable /> }
			{ !userSession && <LivePokerLobbyTableBlurred /> }
			{/* <StreamerSection content={appOptions && appOptions.acf.streamer_section} /> */}

			{/* <MimaGamesSection /> */}

			{/* <LeaderboardPointSection hide={false} content={appOptions && appOptions.acf && appOptions.acf.poker_launch_leaderboard} /> */}
			{/* <LeaderboardHands 
			<LeaderboardHands 
				content={appOptions && appOptions.acf && appOptions.acf.poker_launch_leaderboard} 
				hide={false} 
			/> */}

			{/* <StreamerSection content={appOptions && appOptions.acf.streamer_section} /> */}

			{/* <MimaGamesSection /> */}

			<LazyLoad once height={250} offset={250}>
				<DetailedFancyInfoSection contentBoxes={appBanner && appBanner.content_boxes} />
			</LazyLoad>

			<LazyLoad once height={250} offset={250}>
				<InfoIconSection />
			</LazyLoad>

			<LazyLoad height={350} once>
				<WelcomeSection welcomeInfo={appBanner && appBanner.welcome_box && appBanner.welcome_box} />
			</LazyLoad>

			<LazyLoad height={350} once>
				<FaqSection 
				faqSection={appBanner && appBanner.faq_section && appBanner.faq_section} />
				<Spacer size={50} />
				<SubscribeSection />
			</LazyLoad>

		</>
	)
}

export default Landing;
