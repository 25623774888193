import React from "react"
import axios from 'axios'
import { Link } from "react-router-dom"

import { headerMainMenu } from '../../../General/Header/HeaderData'

import './GamesMenu.scss'
import styled from "styled-components"
import { t } from 'i18next';
import { useUserLang } from "../../../../contexts/LanguageSwitcherProvider"

axios.defaults.baseURL = process.env.REACT_APP_API_URL_STAGING

const GamesMenu = () => {

    const { lang } = useUserLang()

    return (
        
        <GamesMenuWrap className="footer-links-box games">
            <h2>{ t("Games")}</h2>
            <ul>
                { headerMainMenu.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link to={(lang!=='en' ? '/' + lang : '') + item.to}>{  ( t(item.label)) }</Link>
                        </li>
                    ) 
                }) }
            </ul>
        </GamesMenuWrap>

    )
}

export default GamesMenu

const GamesMenuWrap = styled.div`
    margin-top: 2rem;
`