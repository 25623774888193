import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { motion, AnimatePresence } from 'framer-motion'

import { useUserLogged } from "../../../contexts/UserLoggedProvider"
import { useAppOptions } from '../../../contexts/AppOptionsProvider'
import { useUserSession } from '../../../contexts/UserSessionProvider'

import ErrorOutput from '../../Output/ErrorOutput/ErrorOutput'
import SuccessOutput from '../../Output/SuccessOutput/SuccessOutput'
import LoadingOutput from '../../Output/LoadingOutput/LoadingOutput'
import TextInput from '../../Utils/TextInput/TextInput'
import SubmitButton from '../../Utils/SubmitButton/SubmitButton'
import Image from '../../Utils/Image/Image'

import './LoginForm.scss'
import { useLoginPopup, useChangePasswordPopup, useQuickRegisterPopup } from '../../../contexts/LoginPopupProvider'
import ChangePasswordPopupForm from '../../Utils/ChangePasswordPopupForm/ChangePasswordPopupForm'
import { isMobile } from 'react-device-detect'
import { isWebpSupported } from 'react-image-webp/dist/utils';
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";

import SMSActivationModal from '../SMSActivationModal/SMSActivationModal'
import { t } from 'i18next'
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider'


export default function LoginForm({ returnPath='/' }){

    const location = useLocation()
    const navigate = useNavigate()
    const { setUserSession } = useUserSession()


    const appOptions = useAppOptions()

    const [ accountSmsActivated, setAccountSmsActivated ] = useState(null)
    const [ openSMSModal, setOpenSMSModal ] = useState(true)

    const [ randomImage, setRandomImage ] = useState(null)
    const [ mobilePhoneMumber, setMobilePhoneMumber ]  = useState(null)
    const [ smsCode, setSmsCode ]  = useState(null)
    const [ email, setEmail ] = useState(null)

    const { changePasswordPopup } = useChangePasswordPopup()
    const [password, setPassword] = useState(null)
    const [togglePassword, setTogglePassword] = useState(false)
    
    const [errorResponse, setErrorResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    
    const { lang } = useUserLang()
    const { userLogged, setUserLogged } = useUserLogged()
    const { loginPopup, setLoginPopup } = useLoginPopup()
    const { setQuickRegisterPopup } = useQuickRegisterPopup()
    
    const loginPredefined = loginPopup && loginPopup!==true ? loginPopup : false
    const [usernameOrEmail, setUsernameOrEmail] = useState(loginPredefined)

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) { setLoginPopup(false) }
    }, [setLoginPopup]);


    useEffect(() => {
        if(!loginPopup) return
        setRandomImage(Math.floor(Math.random() * 3))
    }, [loginPopup])

    const recaptchaRef = useRef(null);
    const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // change to your site key
    const [ recaptchaResponse, setRecaptchaResponse ] = useState();
    const v2Callback = (token, refreshToken) => {
        setRecaptchaResponse(token)
    }

    useEffect(() => {
        document.addEventListener("keydown", escFunction);
        return () => { document.removeEventListener("keydown", escFunction); };
    }, [escFunction]);


    useEffect(() => {
        if(userLogged){
            setTimeout(() => {
                if(location.pathname==='/login'){
                    navigate(returnPath)
                }
                setLoginPopup(false)
            }, 1250)
        } 
    }, [location.pathname, navigate, userLogged, returnPath, setLoginPopup])
    

    

    const handleSubmit = async e => {
        e.preventDefault();

        

        let parameters = {
            username: usernameOrEmail,
            password: password,
            recaptcha:recaptchaResponse
        }

        if((usernameOrEmail === null || usernameOrEmail === "") && (password === null || password === "")){
            setErrorResponse(t("Username or E-mail and Password cannot be empty"))
            return;
        }

        if(usernameOrEmail === null || usernameOrEmail === ""){
            setErrorResponse(t("Username or E-mail cannot be empty"))
            return;
        }

        if(password === null || password === ""){
            setErrorResponse(t("Password cannot be empty"))
            return;
        }
        
        setIsLoading(true)
        setErrorResponse(null)

        if(!recaptchaResponse){
            setErrorResponse(t("Please complete the reCaptcha process."))
        }

        
        axios.post(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/auth/v2/login/', parameters)
        .then((response) => {
            setPassword('')
            setUsernameOrEmail('')
            setUserLogged(true)
            setIsLoading(false)
            localStorage.setItem('authJwt',response.data.token)

            axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/accounts/profile/profile-data/', {
                headers:{
                    "Authorization": "JWT " + localStorage.getItem('authJwt')
                }
            })
            .then((response) => {
                setUserSession(response.data)
            })

        }).catch(function (error) {
            
            let error_response = ''
            if(error.response.data.code === "account_not_active"){

                //error_response = " We sent you sms activation code to your mobile phone";
                setEmail(error.response.data.profile.email)
                setMobilePhoneMumber(error.response.data.profile.mobile_phone_number)
                setAccountSmsActivated(false)
                setOpenSMSModal(true)
                
                /*  const parameters = {
                    "mobile_phone_number": error.response.data.profile.mobile_phone_number,
                    "recaptcha":recaptchaResponse
                }
        
                axios.post(process.env.REACT_APP_API_URL_STAGING + "/api/v1/engine/auth/v2/resend-code/", parameters).then((response) => {
                    
                }).catch((error) => {
                    
                }) */

                
            }else{
                setErrorResponse(error.response.data.details+error_response);
                setIsLoading(false)
            }
            recaptchaRef.current?.reset();
        })
        
    }

    

    const motionWrapperOpen = { opacity: 1 }
    const motionWrapperClosed = { opacity: 0 }
    const motionOpen = { y: 0 } 
    const motionClosed = { y: 50 }

    return (
        <>
        
        { accountSmsActivated===false && 
            <SMSActivationModal 
                email={email} 
                smsCode={smsCode} 
                openSMSModal={openSMSModal} 
                setUserLogged={setUserLogged} 
                password={password} 
                setPassword={setPassword} 
                setUserSession={setUserSession} 
                setSmsCode={setSmsCode} 
                setLoginPopup={setLoginPopup} 
                mobilePhoneMumber={mobilePhoneMumber} 
                accountSmsActivated={accountSmsActivated} 
                setAccountSmsActivated={setAccountSmsActivated} /> }

        <AnimatePresence>
            { loginPopup && 
                <motion.div 
                    initial={motionWrapperClosed} 
                    animate={motionWrapperOpen} 
                    exit={motionWrapperClosed} 
                    transition={{ type: "linear", stiffness: 550 }}
                    className="login-container form-section">
                    { loginPopup && 
                        <motion.div 
                        initial={motionClosed} 
                        animate={motionOpen} 
                        exit={motionClosed} 
                        transition={{ type: "tween", stiffness: 350 }}
                        className='login-popup' id='login-popup'>
                            <div className='login-image' style={{ 
                                backgroundImage: 'url('+ (appOptions && randomImage!==null && appOptions.acf.login_images[randomImage].image.url + (isWebpSupported ? '.webp' : '')) +')'
                            }}>
                            </div>
                            <div className="login-wrapper">
                                <h3>{ t("Login") }</h3>
                                <form onSubmit={handleSubmit}>

                                    <TextInput 
                                        autoFocus={!isMobile} 
                                        required={true} 
                                        label={t("Username or E-mail")}
                                        type="text" 
                                        setValue={setUsernameOrEmail} 
                                        inputmode="email" />

                                    <div className="password-wrap">
                                        <TextInput required={true} label={t("Password")} type={togglePassword ? "text" : "password"}  setValue={setPassword}  />
                                        <img className="btn-pwd-toggle" onClick={() => { setTogglePassword(!togglePassword) }} alt="Toggle Password" src={"/images/icons/icon-eye-"+ (togglePassword ? 'closed' : 'open') +".svg"} />
                                    </div>
                                
                                    <Link onClick={() => { setLoginPopup(false) }} to="/forgot-password" className='forgot-password d-block mt-1'>{ t("Forgot Password") }</Link>

                                    <div id="recaptcha-badge">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey={sitekey}
                                            theme={'dark'}
                                            hl={lang}
                                            onChange={v2Callback}
                                            tabindex={0}
                                        />
                                    </div>
                                    
                                    <SubmitButton theme="light">{ t("Login") }</SubmitButton>

                                    <p className='signup'>{ t("Don't have an account?") } <span onClick={() => { 
                                        setQuickRegisterPopup(true)
                                        setLoginPopup(false)
                                        setErrorResponse('')
                                        navigate('/')
                                    }}>{ t("Sign Up") }</span></p>
                                    
                                    { isLoading && <LoadingOutput>Logging in...</LoadingOutput> }
                                    { !userLogged && returnPath && returnPath!=='/' && !errorResponse && !isLoading && <ErrorOutput>{ t("You need to login first") }...</ErrorOutput> }
                                    { errorResponse && <ErrorOutput>{ errorResponse }</ErrorOutput> }
                                    { userLogged && !isLoading && !errorResponse && <SuccessOutput >{ t("Logged in successfully") }.</SuccessOutput> }
                                    
                                    
                                </form>
                            </div>
                            <p className='login-exit' id='login-exit' onClick={ () => {
                                    setLoginPopup(false)
                                    setErrorResponse('')
                                    const loginLink = lang!=='en' ? '/' + lang + '/login' : '/login'
                                    if(location.pathname===loginLink){
                                        navigate('/' + (lang!=='en' ? lang : ''))
                                    }
                                }}>
                                <Image src="/images/icons/icon-close.svg"></Image>
                            </p>
                        </motion.div>
                    }
                </motion.div>
            }
            </AnimatePresence>

            { changePasswordPopup && <ChangePasswordPopupForm changePasswordPopup={changePasswordPopup} usernameOrEmail={usernameOrEmail} /> }

        </>
        
    )
}

