import React from 'react'
import { t } from 'i18next'
import styled, { css } from 'styled-components'
import { useAppOptions } from '../../../contexts/AppOptionsProvider'
import { useLoginPopup } from '../../../contexts/LoginPopupProvider'
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider'

export default function LivePokerLobbyTable(){

    const appOptions = useAppOptions()
    const { lang } = useUserLang()
    const { setLoginPopup } = useLoginPopup()

    return (
        <LobbySection id="poker-lobby">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-8">

                        <TableTypeSection>
                            <Heading>
                                <div className='iconTitle'>
                                <Icon width="93" height="92" alt="Live Dealer Tables Icon" src="/images/icons/icon-real-money.svg" />
                                <Title>
                                    {   appOptions && 
                                        appOptions.acf.poker_tables_lobby && 
                                        appOptions.acf.poker_tables_lobby.live_tables && 
                                        <h3 dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.title }} /> }
                                </Title>
                                </div>
                                <div className='iconDesc'> 
                                {  appOptions && 
                                        appOptions.acf.poker_tables_lobby && 
                                        appOptions.acf.poker_tables_lobby.live_tables && 
                                        <p dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.description }} /> }
                                </div>
                            </Heading>
                            <div className="row">
                                <div className="col-12">
                                    <TableHeadingsBox>
                                        <HeadingColumn className="name">{ t("Name")}</HeadingColumn>
                                        <HeadingColumn className="type">{ t("Type")}</HeadingColumn>
                                        <HeadingColumn className="stake sortable">{ t("Stake")}</HeadingColumn>
                                        <HeadingColumn className="players-online">{ t("Players")}</HeadingColumn>
                                        <HeadingColumn className="join">{ t("Play now")}</HeadingColumn>
                                    </TableHeadingsBox>
                                    <TableImage onClick={ () => { setLoginPopup(true); }}>
                                        <img src={"/images/mima-lobby-blurred-tables"+ (lang==='en' ? '' : '-' + lang) +".jpg"} alt="Tabel Live Poker Lobby" />
                                    </TableImage>
                                </div>
                            </div>
                        </TableTypeSection>

                    </div>
                </div>
            </div>
        </LobbySection>
    )
}

const LobbySection = styled.section`
    padding: 2rem 0;
    padding-bottom: 7rem;
    border-top: 5px solid var(--orange);
    @media (min-width: 0px) and (max-width: 767px){
        padding: 0rem 0;
    }
`

const TableTypeSection = styled.div`
    h3{
        @media (min-width: 0px) and (max-width: 767px){
            font-size: 1.875rem;
        }
        @media (min-width: 768px){
            font-size: 2.5rem;
        }
        margin: 0;
    }
`

const HeadingColumn = styled.div`
    &.name{ width: 25%; }
    &.type{ width: 20%; }
    &.stake{ width: 20%; }
    &.players-online{ width: 20%; }
    &.join{ width: 15%; }
    @media (min-width: 0px) and (max-width: 767px){
        font-size: 0.75rem;
    }
    &.sortable{
        cursor: pointer;
        display: flex;
        align-items: center;
        &:hover{
            opacity: 0.5;
        }
        &:after{
            content: '';
            display: block;
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #FFF;
            flex: 0 0 6px;
            margin-left: 1rem;
            transition: all .2s ease-out;
            transform: ${props => props.sort==='asc' ? css`rotateZ(180deg)` : css`rotateZ(0deg)` };
        }
    }

`

const TableHeadingsBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1.5rem;
    background: linear-gradient(135deg, #333 0%, #000 100%);
    border-radius: 0rem;
    border: 1px solid #ffffff;
    font-weight: bold;
    & > div{
        position: relative;
    }
`

const Heading = styled.div`
    margin-top: 4rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 0px) and (max-width: 767px){
        margin-top: 2rem;
        flex-direction: row;
        align-items: flex-start;
    }
    @media (min-width: 768px){
        display: flex;
    }
    h3{
        font-size: 2rem;
        max-width: 200px;
    }
    p{
        margin: 0;
        @media (min-width: 768px) and (max-width: 1630px){
            min-height: 80px;
        }
    }
    .iconTitle{
        justify-content: center;
        align-items: center;
        display: flex;
        @media (min-width: 0px) and (max-width: 767px){
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
    .iconDesc {
        margin-top: 15px;
        margin-bottom: 0px;
        @media (min-width: 0px) and (max-width: 767px){
            margin-top: 10px;
            margin-bottom: 35px;
        }
    }
`

const Icon = styled.img`
    width: auto;
    @media (min-width: 0px) and (max-width: 767px){
        height: 70px;
        margin-bottom: 0.5rem;
    }
    @media (min-width: 768px){
        height: 90px;
    }
    margin-right: 1rem;
    padding: 0.85rem;
    border-radius: 1rem;
    border: 2px solid var(--orange);
`

const Title = styled.div``

const TableImage = styled.div`
    cursor: pointer;
    img{
        width: 100%;
        height: auto;
    }
`