import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { isMobile, isTablet } from "react-device-detect";
import { isWebpSupported } from 'react-image-webp/dist/utils'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Lazy, Autoplay, Pagination, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.css'

import './HeroBannerSlider.scss'

import MimaLoader from "../AppLoader/MimaLoader"

import { useNavigate } from "react-router-dom"
import { useAppMainBanner } from "../../../contexts/AppOptionsProvider"

const HeroVideoDiv = ({ backgroundVideo }) => {

    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const onLoadedData = () => {
        setTimeout(() => {
            setIsVideoLoaded(true);
        }, 300)
    };

    return (
        <div className="hero-banner-slider">
            <video 
                className="slide-video" 
                src={backgroundVideo} 
                onLoadedData={onLoadedData}
                style={{ opacity: isVideoLoaded ? 1 : 0 }}
                autoPlay 
                loop 
                muted 
                playsInline>
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

const HeroBannerSlider = () => {

    const navigate = useNavigate()
    const appBanner = useAppMainBanner()
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {

        if(!appBanner) return
        if(!appBanner.hero_slider) return
        if(appBanner.hero_slider.length===0) return

        const preloadImages = (imageUrls) => {
            const promises = imageUrls.map((url) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = url;
                    img.onload = () => {
                        resolve();
                    };
                });
            });

            Promise.all(promises).then(() => {
                setTimeout(() => {
                    setImagesLoaded(true)
                }, 300)
            });
        };

        let imageUrls = []
        for(let i=0; i<appBanner.hero_slider.length; i++){
            imageUrls.push(appBanner.hero_slider[i].image.url + (isWebpSupported ? '.webp' : ''))
            imageUrls.push(appBanner.hero_slider[i].image.url)
        }

        preloadImages(imageUrls);

    }, [appBanner]);
    
    return (
        <div className={"hero-banner-slider-wrap" + (appBanner && appBanner.hero_slider && imagesLoaded ? "" : " loading") + (imagesLoaded ? ' loaded' : '') }>
            { appBanner && appBanner.hero_slider && imagesLoaded && (
                <Swiper
                    className={"hero-slider"}
                    spaceBetween={0}
                    slidesPerView={1.08}
                    //initialSlide={Math.floor((appBanner.hero_slider.length)/2)} 
                    loop={true}
                    centeredSlides={true}
                    modules={[Lazy, Autoplay, Pagination, EffectFade]}
                    effect="fade"
                    speed={1000}
                    autoplay={true} 
                    pagination={{ clickable: true }}
                    preloadImages={true} 
                    lazy={{
                        loadPrevNext: true,
                        loadPrevNextAmount: 0
                    }} >
                    { appBanner.hero_slider.map((item, key) => {
                        
                        let imageUrl = item.image && item.image.sizes ? item.image.url : null
                        imageUrl = (isMobile && !isTablet) ? item.mobile.image.url : imageUrl
                        let videoUrl = (isMobile && !isTablet) ? (item.mobile.video ? item.mobile.video.url : item.video.url) : item.video.url
                        
                        return(
                            <SwiperSlide 
                                className={item.links_to ? "hasLink" : ""} 
                                onClick={() => { 
                                    if(item.links_to.includes('http')){ window.open(item.links_to, '_blank') }else{ navigate(item.links_to) }
                                }} key={key} >
                                { item.image ? (
                                    <HeroBannerSlide>
                                        <div className="hero-banner-slider" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                                    </HeroBannerSlide>
                                ) : (
                                    <HeroVideoDiv backgroundVideo={videoUrl} />
                                )}
                            </SwiperSlide>
                            )
                        }) 
                    }  
                </Swiper>
            )}
            { (!appBanner || !appBanner.hero_slider || !imagesLoaded) && <LoadingSlider><MimaLoader className="loader" /></LoadingSlider> }
        </div>
    );

}

export default HeroBannerSlider;

const HeroBannerSlide = styled.div`
    cursor: pointer;
`

const LoadingSlider = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`
